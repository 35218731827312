

<template>
	<div class="tcontainer">
        <Toast />
        <div class="clearfix ttitle">
            <div class="title">
                Rate Chart
            </div>
        </div>
        <div style="padding: 30px 0; background: #fff; margin-bottom: 10px; border: 1px solid #eee; border-top: 0;">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4">
                        <form action="" @submit.prevent="submitContactAdmin">

                            <div class="form-group ui-float-label">
                                <input type="text" class="form-control" :class="{'is-invalid': fcontrol.title?.hasError && fcontrol.title.touched}" v-model="fdata.title" @blur="fcontrol.title.touched=true" placeholder=" "/>
                                <div class="invalid-feedback" v-if="fcontrol.title?.hasError">
                                    <span v-if="fcontrol.title.errors.required">You must enter title</span>
                                </div>
                                <label>Title</label>
                            </div>
                            <div class="form-group ui-float-label">
                                <textarea rows="5" class="form-control" :class="{'is-invalid': fcontrol.message?.hasError && fcontrol.message.touched}" v-model="fdata.message" @blur="fcontrol.message.touched=true" placeholder=" Message "></textarea>
                                <div class="invalid-feedback" v-if="fcontrol.message?.hasError">
                                    <span v-if="fcontrol.message.errors.required">You must enter message</span>
                                    <span v-if="fcontrol.message.errors.min">Message must contain at least {{fcontrol.message.errors.min.minLength}} characters</span>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Send</button>
                        </form>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
            </div>
        </div>

        
	</div>
</template>

<script>
import Toast from 'primevue/toast';
export default {
    components: {
        Toast,
    },
    data(){
        return {
            fdata: {
                title: '',
                message: ''
            },
            fvalidator: {
                title: 'required',
                message: 'required|min:10'
            }
        }
    },
    mounted() {
    },
    methods: {
        submitContactAdmin(){
            if(this.fHasError()) return;
            
            this.post("center/contact-admin", this.fdata).then(res=>{
                if(res.success){
                    this.$toast.add({severity:'success', summary: 'Success', detail:'Message sent successfully', life: 3000});
                    this.freset();
                }
            });
        }
    }
}
</script>
<style scoped>
</style>
